import { Col, Row } from "reactstrap";
import "./styles.scss";


const Banner = () => {
  return (
    <Row noGutters className="bg-secondary mt-5 d-flex">
      <Col xs={12} md={6} className="order-2 order-md-1">
        <img
          className="img-cover"
          src="/images/order_w_800.webp"
          srcSet={`/images/order_w_400.webp 400w, /images/order_w_800.webp 800w`}
          sizes="(max-width: 400px) 400px, 800px"
          width="100%"
          height="100%"
          alt="dress and hairbow in a box with flowers decoration"
          loading="lazy"
        />
      </Col>

      <Col xs={12} md={6} className="order-1 order-md-2 text-center d-flex flex-column justify-content-center">
        <h1 className="mt-5 text-center primary">
          Turn heads and steal the show!
        </h1>
        <h2 className="mb-5 px-5">Get 15% off now!</h2>
        

        <Col xs={9} sm={7} className="mx-auto">
          <div class="klaviyo-form-QSK2BD"></div>
        </Col>
      </Col>
    </Row>
  );
};

export default Banner;
