import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingHeart,
  faDollarSign,
  faTrademark,
  faBoxes,
  faCrown,
  faEarthEurope,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const Features = () => {
  const features = [
    {
      title: "Handmade to order",
      text: "Unique garment, that fits perfectly.",
      icon: faHandHoldingHeart,
    },
    {
      title: "Duty Free Shopping",
      text: "Shipping to the USA with duties covered.",
      icon: faDollarSign,
    },
    {
      title: "Exclusive Designs",
      text: "You won't find them anywhere else.",
      icon: faTrademark,
    },
    {
      title: "Small quantities",
      text: "So your daughter can feel special and stand out from the crowd.",
      icon: faBoxes,
    },
    {
      title: "Truly Royal Experience",
      text: "Bespoke dresses made from the same iconic Liberty of London fabrics chosen by the UK Royal Family.",
      icon: faCrown,
    },
    {
      title: "Made in Europe",
      text: "Proudly made in Europe, using techniques that have been passed down through generations.",
      icon: faEarthEurope,
    },
  ];

  return (
    <div className="mt-5 pt-5 text-center" style={{maxWidth: '100%', overflow: 'hidden'}}>
      <h2 className="mb-5 kelvinch primary fs-1">Why choose Diana Louise?</h2>
      <Row className="grid-container" xs={1} sm={2} lg={3}>
        {features.map(({ title, text, icon }) => (
          <Col className="px-5 py-4 px-md-5 py-md-4 ">
            <FontAwesomeIcon
              icon={icon}
              size="2xl"
              className="color-primary mb-4"
            />
            <h3 className='fs-4'>{title}</h3>
            <p>{text}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Features;
