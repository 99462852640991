import { Col, Row } from "reactstrap";
import SocialMedia from "../SocialMedia";

const About = () => {
  return (
    <div className="bg-secondary my-5 py-5" style={{maxWidth: '100%', overflow: 'hidden'}}>
      <h2 className="pb-5 pt-3 text-center kelvinch primary fs-1">About</h2>

      <Row className="gy-5">
        <Col
          lg={6}
          xs={12}
          className="d-flex justify-content-center align-items-center"
        >
          <img src="/images/about.webp" style={{ maxHeight: "600px", height: "auto" }} alt="workshop view"/>

        </Col>
        <Col
          lg={6}
          xs={12}
          className="d-flex justify-content-center align-items-center px-5"
        >
          <p className="fs-5 lh-lg">
            At{" "}
            <a href="https://dianalouise.com" className="text-decoration-none">
              Diana Louise
            </a>{" "}
            we believe in creating special moments for little girls that will
            last a lifetime. Our dresses are designed with love and made to
            order, ensuring that each piece is unique and tailored to your
            child's needs. We only use the finest Liberty of London fabrics,
            renowned for their quality and beauty.
          </p>
          
        </Col>
        <Col
          lg={6}
          xs={12}
          className="d-flex justify-content-center align-items-center px-5"
        >
          <SocialMedia />
          </Col>
      </Row>
    </div>
  );
};

export default About;
