import { Col } from "reactstrap";
import "./styles.scss";

const Quote = () => {
  return (
    <div className="text-center my-5 py-5">
      <Col xs={10} lg={7} className="mx-auto">
        <blockquote className="quote fs-4 text-muted fst-italic">
          True luxury is about more than just the price tag - it's about the
          experience of owning something special that will last a lifetime.
        </blockquote>
        <img
                src={`/images/signature.png`}
                alt='Diana Hand Signature'
              />
      </Col>
    </div>
  );
};

export default Quote;
