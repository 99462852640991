import "./styles.scss";
import Features from "@/components/Features";
import Hero from "@/components/Hero";
import Testimonials from "@/components/Testimonials";
import About from "../About";
import Quote from "../Quote";
import Banner from "../Banner";
import Gallery from "../Gallery";
import Footer from "../Footer";
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { sendEvent } from "../../lib/events";

function App() {
  useEffect(() => {
    const eventID = uuidv4();
    // eslint-disable-next-line no-undef
    fbq("track", "PageView", {}, {eventID});
    async function fetchData() {
      await sendEvent(eventID);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Hero />
      <Features />
      <Testimonials />
      <Quote />
      <About />
      <Gallery />
      <Banner />
      <Footer withCopyright={true} />
    </div>
  );
}

export default App;
