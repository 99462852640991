import axios from "axios";

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const sendEvent = async (eventID) => {
    await axios.post('https://discover.dianalouise.com/api/events/', {
        location: window.location.href,
        user_agent: window.navigator.userAgent,
        event_id: eventID,
        fbp: getCookie('_fbp'),
        opt_out: window.navigator.doNotTrack,
    })

}