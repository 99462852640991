import { useState } from "react";
import Masonry from "react-masonry-css";
import FsLightbox from "fslightbox-react";

import "./styles.scss";

const Gallery = () => {
  const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slideIdx: 0
	});

	const openLightboxOnSlide = (idx) => {
		setLightboxController((prevState) => ({
			toggler: !prevState.toggler,
			slideIdx: idx
    }));
	}

  const images = [
    10,2,3,11,4,12,7,5,6,8,1,9
  ];
  const sources = images.map(img=>`/images/collage/original/${img}.webp`)

  const breakpointsConfig = {
    default: 4,
    1200: 3,
    992: 2,
    768: 2,
    576: 2,
  };

  return (
    <div className="my-5 py-5">
      <Masonry
        breakpointCols={breakpointsConfig}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {images.map((img, idx) => (
          <img
            alt={`product ${idx}`}
            key={idx}
            src={`/images/collage/w_800/${img}.webp`}
            srcSet={`/images/collage/w_400/${img}.webp 400w, /images/collage/w_600/${img}.webp 600w, /images/collage/w_800/${img}.webp 800w`}
            sizes="(max-width: 768px) 400px, (max-width: 992px) 600px, 800px"

            width="100%"
            height="auto"
            className="my-3"
            role="button"
            onClick={() => openLightboxOnSlide(idx)}
            loading="lazy"
          />
        ))}
      </Masonry>

      <FsLightbox toggler={lightboxController.toggler} sources={sources} sourceIndex={lightboxController.slideIdx} />
    </div>
  );
};

export default Gallery;
