import { Col } from "reactstrap";
import "./styles.scss";

const Hero = () => {
  return (
    <div className='p-sm-5' style={{position: 'relative'}}>
      <div style={{position: 'absolute', left:0, right:0, top:0, height:'100%', zIndex:'-999'}}>
      <img
          style={{objectFit:'cover', objectPosition:'right bottom'}}
          src="/images/hero/w_1600.webp"
          srcSet={`/images/hero/w_660.webp 660w, /images/hero/w_973.webp 973w, /images/hero/w_1600.webp 1600w`}
          sizes="(max-width: 576px) 660px, (max-width: 992px) 973px, 1600px"
          width="100%"
          height="100%"
          alt="Girl holding a flower in smoked Diana Louise dress"
        />
      </div>
      <Col lg={6} className='hero-overlay mx-auto text-center'>
        <h1 className='mb-5 mt-2 text-center'>
          <a href='https://dianalouise.com' className='text-decoration-none'>
            Diana Louise
          </a>
        </h1>
        <h2 className='fw-bold'>Bring Home the Royal Experience</h2>
        <p>Dress Your Daughter in the same iconic fabric as the UK Royal Family</p>

        <Col xs={9} sm={7} className='mx-auto'>
          <img
            src="/images/made-with-liberty.webp"
            width='60%'
            height='auto'
            alt='made with liberty fabric logo'
            className='pt-3 pb-4'
            loading='lazy'
          />

        <div class="klaviyo-form-QSK2BD"></div>
        </Col>
      </Col>
    </div>
  );
};

export default Hero;
