import Slider from "react-slick";

import "./styles.scss";

const Testimonials = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    speed: 200,
    arrows: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="testimonials-container bg-secondary my-5 py-5" style={{maxWidth: '100%', overflow: 'hidden'}}>
      <h2 className="pb-5 pt-3 text-center kelvinch primary fs-1">Customer reviews</h2>
      <div className="pb-5">
        <Slider {...settings}>
          {[...Array(9).keys()].map((slideIdx) => {
              const slideNum = slideIdx+1;
              return (
                <img
                key={slideIdx}
                src={`/images/testimonials/${slideNum}/${slideNum}_w_490.webp`}
                alt={`Slide ${slideNum}`}
                sizes="(max-width: 576px) 400px, 490px"
                srcSet={
                  `/images/testimonials/${slideNum}/${slideNum}_w_400.webp 400w, /images/testimonials/${slideNum}/${slideNum}_w_490.webp 490w`
                }
                loading="lazy"
              />
              )
            }
          )}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
