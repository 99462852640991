import { Col } from "reactstrap";
import SocialMedia from "../SocialMedia";

const Footer = () => {
  return (
    <div className="my-5">
      <Col xs={6} className="mx-auto text-center">
       <SocialMedia />
      </Col>
      <p className="text-center text-muted">
        &copy; {new Date().getFullYear()} Diana Louise
      </p>
    </div>
  );
};

export default Footer;
