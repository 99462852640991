import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

const SocialMedia = () => {
  let icons = [
    {
      name: 'website',
      icon: faGlobe,
      href: "https://dianalouise.com",
    },
    {
      name: 'instagram',
      icon: faInstagram,
      href: "https://www.instagram.com/dianalouisecloset",
    },
    {
      name: 'facebook',
      icon: faFacebookF,
      href: "https://www.facebook.com/dianalouisecloset",
    },
  ];

  return (
    <>
      {icons.map((icon) => (
        <a href={icon.href} key={icon.name}>
          <FontAwesomeIcon
            icon={icon.icon}
            size="2xl"
            className="color-primary mb-5 mx-4"
          />
        </a>
      ))}
    </>
  );
};

export default SocialMedia;
